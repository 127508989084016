import React from "react";
import LinkWithImageOverlayComponent from "../Components/HomeImage";

function Home() {
  return (
    <div class="grid grid-cols-1 md:grid-cols-2 lg:grid-cols-2 gap-12 mx-12 justify-items-center mb-5">
      {/* Mountains */}
      <LinkWithImageOverlayComponent
        imageUrl="./images/nature/romania_w10.jpg"
        alt="Mountains"
        overlayText="Mountains"
        link="/mountains"
      />
      {/* Cities */}
      <LinkWithImageOverlayComponent
        imageUrl="./images/cities/soton-2.jpg"
        alt="Cities"
        overlayText="Cities"
        link="/cities"
      />
      {/* Automotive */}
      <LinkWithImageOverlayComponent
        imageUrl="./images/automotive/distrct-1.jpg"
        alt="Automotive"
        overlayText="Automotive"
        link="/automotive"
      />
      {/* Beach */}
      <LinkWithImageOverlayComponent
        imageUrl="./images/beach/cornwall_b1.jpg"
        alt="Beach"
        overlayText="Beach"
        link="/seaside"
      />
    </div>
  );
}

export default Home;
