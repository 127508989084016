import React from "react";
import ImageGallery from "../Components/ImageGallery";

function Automotive() {
  return (
    <div class="grid grid-cols-1 md:grid-cols-1 lg:grid-cols-1 gap-6 mx-12 justify-items-center mb-5 max-w-full">
      
      {/* image 1 */}
      <ImageGallery
        imageUrl="./images/automotive/cars-6.jpg"
        alt="London"
        overlayText="London"
      />

      {/* image 2 */}
      <ImageGallery
        imageUrl="./images/automotive/distrct-2.jpg"
        alt="DISTRCT"
        overlayText="DISTRCT"
      />

      {/* image 3 */}
      <ImageGallery
        imageUrl="./images/automotive/distrct-6.jpg"
        alt="DISTRCT"
        overlayText="DISTRCT"
      />

      {/* image 4 */}
      <ImageGallery
        imageUrl="./images/automotive/distrct-7.jpg"
        alt="DISTRCT"
        overlayText="DISTRCT"
      />

      {/* image 5 */}
      <ImageGallery
        imageUrl="./images/automotive/distrct-3.jpg"
        alt="DISTRCT"
        overlayText="DISTRCT"
      />

      {/* image 6 */}
      <ImageGallery
        imageUrl="./images/automotive/cars-2.jpg"
        alt="DISTRCT"
        overlayText="DISTRCT"
      />

      {/* image 7 */}
      <ImageGallery
        imageUrl="./images/automotive/distrct-4.jpg"
        alt="DISTRCT"
        overlayText="DISTRCT"
      />

      {/* image 8 */}
      <ImageGallery
        imageUrl="./images/automotive/distrct-5.jpg"
        alt="DISTRCT"
        overlayText="DISTRCT"
      />

      {/* image 9 */}
      <ImageGallery
        imageUrl="./images/automotive/enduro-6.jpg"
        alt="DISTRCT"
        overlayText="DISTRCT"
      />

  {/* image 10 */}
  <ImageGallery
        imageUrl="./images/automotive/enduro-7.jpg"
        alt="DISTRCT"
        overlayText="DISTRCT"
      />
        {/* image 11 */}
        <ImageGallery
        imageUrl="./images/automotive/enduro-5.jpg"
        alt="DISTRCT"
        overlayText="DISTRCT"
      />
  {/* image 12 */}
  <ImageGallery
        imageUrl="./images/automotive/enduro-3.jpg"
        alt="DISTRCT"
        overlayText="DISTRCT"
      />
        {/* image 13 */}
        <ImageGallery
        imageUrl="./images/automotive/enduro-2.jpg"
        alt="DISTRCT"
        overlayText="DISTRCT"
      />   
      {/* image 14 */}
        <ImageGallery
        imageUrl="./images/automotive/enduro-1.jpg"
        alt="DISTRCT"
        overlayText="DISTRCT"
      />


</div>
  );
}

export default Automotive;
