import React from "react";

function ImageGallery({ imageUrl, alt, overlayText }) {
  return (
    <div className="relative bg-gray-200 aspect-portrait h-[55vh]" style={{maxWidth: 'inherit'}}>
      <img
        className="absolute inset-0 w-full h-full object-cover"
        src={imageUrl}
        alt={alt}
      />
      <div className="absolute inset-0 flex flex-col justify-center items-center">
        {/* <div className="card-title text-white text-center">{overlayText}</div> */}
      </div>
    </div>
  );
}

export default ImageGallery;
