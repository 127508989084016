import React from 'react';

function LinkWithImageOverlayComponent({ imageUrl, alt, overlayText, link }) {
  return (
    <a href={link}>
      <div className="relative bg-gray-200 aspect-portrait h-[55vh]">
        <img
          className="absolute inset-0 w-full h-full object-cover"
          src={imageUrl}
          alt={alt}
        />
        <div className="absolute inset-0 flex flex-col justify-center items-center">
          <div className="card-title text-white text-center">{overlayText}</div>
        </div>
      </div>
    </a>
  );
}

export default LinkWithImageOverlayComponent;
