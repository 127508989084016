import React from "react";
import ImageGallery from "../Components/ImageGallery";

function Cities() {
  return (
    <div class="grid grid-cols-1 md:grid-cols-1 lg:grid-cols-1 gap-6 mx-12 justify-items-center mb-5 max-w-full">
      {/* image 1 */}
      <ImageGallery
        imageUrl="./images/cities/soton-1.jpg"
        alt="Southampton"
        overlayText="Southampton"
      />

 {/* image 2 */}
      <ImageGallery
        imageUrl="./images/cities/manchester-2.jpg"
        alt="Manchester"
        overlayText="Manchester"
      />

 {/* image 3 */}
      <ImageGallery
        imageUrl="./images/cities/manchester-3.jpg"
        alt="Southampton"
        overlayText="Southampton"
      />

 {/* image 4 */}
      <ImageGallery
        imageUrl="./images/cities/manchester-1.jpg"
        alt="Manchester"
        overlayText="Manchester"
      />

 {/* image 5 */}
      <ImageGallery
        imageUrl="./images/cities/london-1.jpg"
        alt="London"
        overlayText="London"
      />

 {/* image 6 */}
      <ImageGallery
        imageUrl="./images/cities/buc-w-1.jpg"
        alt="Bucharest"
        overlayText="Bucharest"
      />

 {/* image 7 */}
      <ImageGallery
        imageUrl="./images/cities/manchester-5.jpg"
        alt="Manchester"
        overlayText="Manchester"
      />



  </div>
  );
}

export default Cities;
