import React from "react";
import ImageGallery from "../Components/ImageGallery";

function Mountains() {
  return (
    <div class="grid grid-cols-1 md:grid-cols-1 lg:grid-cols-1 gap-6 mx-12 justify-items-center mb-5 max-w-full">
      {/* image 1 */}
      <ImageGallery
        imageUrl="./images/nature/romania_m3.jpg"
        alt="Transfagarsan"
        overlayText="Transfagarsan"
      />

      {/* image 2 */}
      <ImageGallery
        imageUrl="./images/nature/romania_m4.jpg"
        alt="Transfagarsan"
        overlayText="Transfagarsan"
      />

      {/* image 3 */}
      <ImageGallery
        imageUrl="./images/nature/romania_m5.jpg"
        alt="Transfagarsan"
        overlayText="Transfagarsan"
      />
      {/* image 4 */}
      <ImageGallery
        imageUrl="./images/nature/romania_m2.jpg"
        alt="Cheii"
        overlayText="Cheile"
      />

      {/* image 5 */}
      <ImageGallery
        imageUrl="./images/nature/romania_w14.jpg"
        alt="Romania"
        overlayText="Romania"
      />


      {/* image 6 */}
      <ImageGallery
        imageUrl="./images/nature/romania_w13.jpg"
        alt="Romania"
        overlayText="Romania"
      />

      {/* image 7 */}
      <ImageGallery
        imageUrl="./images/nature/romania_w12.jpg"
        alt="Romania"
        overlayText="Romania"
      />
      {/* image 8 */}
      <ImageGallery
        imageUrl="./images/nature/romania_w10.jpg"
        alt="Romania"
        overlayText="Romania"
      />
      {/* image 9 */}
      <ImageGallery
        imageUrl="./images/nature/romania_w9.jpg"
        alt="Romania"
        overlayText="Romania"
      />
      {/* image 10 */}
      <ImageGallery
        imageUrl="./images/nature/romania_w4.jpg"
        alt="Romania"
        overlayText="Romania"
      />   
       {/* image 11 */}
      <ImageGallery
        imageUrl="./images/nature/romania_w2.jpg"
        alt="Romania"
        overlayText="Romania"
      />
      {/* image 12 */}
      <ImageGallery
        imageUrl="./images/nature/romania_w1.jpg"
        alt="Romania"
        overlayText="Romania"
      />
    </div>
  );
}

export default Mountains;
