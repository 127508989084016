import React from "react";
import ImageGallery from "../Components/ImageGallery";

function Beach() {
  return (
    <div class="grid grid-cols-1 md:grid-cols-1 lg:grid-cols-1 gap-6 mx-12 justify-items-center mb-5 max-w-full">
      
      {/* image 1 */}
      <ImageGallery
        imageUrl="./images/beach/cornwall_b2.jpg"
        alt="Cornwall"
        overlayText="Cornwall"
      />
      {/* image 2 */}
      <ImageGallery
        imageUrl="./images/beach/cornwall_b3.jpg"
        alt="Cornwall"
        overlayText="Cornwall"
      />
      {/* image 3 */}
      <ImageGallery
        imageUrl="./images/beach/croatia_b3.jpg"
        alt="Croatia"
        overlayText="Croatia"
      />
      {/* image 4 */}
      <ImageGallery
        imageUrl="./images/beach/croatia_b2.jpg"
        alt="Croatia"
        overlayText="Croatia"
      />
      {/* image 5 */}
      <ImageGallery
        imageUrl="./images/beach/croatia_b4.jpg"
        alt="Croatia"
        overlayText="Croatia"
      />
      {/* image 6 */}
      <ImageGallery
        imageUrl="./images/beach/croatia_b6.jpg"
        alt="Croatia"
        overlayText="Croatia"
      />
      {/* image 7 */}
      <ImageGallery
        imageUrl="./images/beach/croatia_b7.jpg"
        alt="Croatia"
        overlayText="Croatia"
      />
      {/* image 8 */}
      <ImageGallery
        imageUrl="./images/beach/croatia_b8.jpg"
        alt="Croatia"
        overlayText="Croatia"
      />
      {/* image 9 */}
      <ImageGallery
        imageUrl="./images/beach/croatia_b9.jpg"
        alt="Croatia"
        overlayText="Croatia"
      />
      {/* image 10 */}
      <ImageGallery
        imageUrl="./images/beach/croatia_b1.jpg"
        alt="Croatia"
        overlayText="Croatia"
      />


</div>
  );
}

export default Beach;
