import React from "react";

function Contact() {
  return (
    <section class="bg-white content-center " style={{ height: 'calc(100vh - 160px)'}}>
      <div class="py-8 lg:py-16 px-4 mx-auto max-w-screen-md">
        <h2 class="mb-4 text-4xl tracking-tight font-extrabold text-center text-gray-900 ">
          Contact Us
        </h2>
        <p class="mb-8 lg:mb-16 font-light text-center text-gray-500 ">
          Interested in collaborating with us? Want to buy some of our artworks? <br></br> Drop us a message below
        </p>
<a
          class="btn btn-outline mx-2"
          href="mailto:popescu.developer@gmail.com"
        >
          Send Email
         </a>
        <a
          class="btn btn-outline mx-2"
          href="https://www.instagram.com/fotografica.ro/"
        >
          Follow on Instagram
        </a>
      </div>
    </section>
  );
}

export default Contact;
