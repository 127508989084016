import './App.css';
import { BrowserRouter as Router, Routes, Route } from 'react-router-dom';
import UnderDevelopment from './Pages/UnderDevelopment';
import Home from './Pages/Home';
import Navbar from './Components/Navbar';
import Mountains from './Pages/Mountains';
import Cities from './Pages/Cities';
import Automotive from './Pages/Automotive';
import Beach from './Pages/Beach';
import Footer from './Components/Footer';
import Contact from './Pages/Contact';

function App() {
  return (
      <>
        <Router>
          <div data-theme="lofi" className="App">
            <Navbar />
            <Routes>
              <Route exact path="/" element={<Home />}></Route>
             {/* <Route exact path="/portofolio" element={<Home />}></Route> */}
             <Route exact path="/mountains" element={<Mountains />}></Route>
             <Route exact path="/cities" element={<Cities />}></Route>
             <Route exact path="/automotive" element={<Automotive />}></Route>
             <Route exact path="/seaside" element={<Beach />}></Route>
             <Route exact path="/contact" element={<Contact />}></Route>
            </Routes>
            <Footer />
          </div>
        </Router>
      </>
  );
}

export default App;
